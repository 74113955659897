import React, { useState } from 'react';
import { IconButton } from '@mui/material';

// * Icons: 
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';

const RequestButton = ({ handleRequest, erase }) => {

    const [selection, setSelection] = useState(false);

    return (
        <IconButton
            className="centered"
            style={{borderRadius: "0.5em", padding: "1em"}}
            onClick={() => {
                if(!selection) handleRequest();
                else erase();
                setSelection(!selection);
            }}
            children={selection ? <ClearIcon/> : <SendIcon/>}
        />
    );
}

export default RequestButton;