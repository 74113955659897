import axios from 'axios';
import { AppMap } from "../utils/AppMap";

const handleRoads = (id, code, lonMin, latMax, lonMax, latMin) => {
    axios.get(`https://api.lightpass.gradis.pl/api/requests/${id}/${code}/roads?lon_min=
        ${lonMin}&lon_max=${lonMax}&lat_min=${latMin}&lat_max=${latMax}`)
    .then(res => {
        console.log(res.data);
        const features = res.data.features;
        AppMap.drawRoads(features);
    })
    .catch(err => {
        console.log(err);
    })
}

export default handleRoads;