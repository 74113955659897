import {Config} from "../utils/Config";

const RelationCountValidator = (polygon) => {
    let maxRelations = Config.getValue("requests_max_relations");
    if (polygon.type === "MultiPolygon" && polygon.coordinates.length > maxRelations) {
        throw new Error(`Podano więcej niż ${maxRelations} obszarów`);
    }

    return true;
};

export default RelationCountValidator;
