import axios from "axios";

export class Texts {

    static get() {
        return JSON.parse(sessionStorage.getItem("texts")) ?? {};
    }

    static getValue(key) {
        let texts = Texts.get();
        if (!Object.keys(texts).includes(key)) return null;
        return texts[key]
    }

    static async fetch(url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_CONFIG_TEXTS) {

        return axios.get(url, {
            headers: {
                'Accept-Language': localStorage.getItem("language") || "pl"
            }
        })
            .then((response) => {
                sessionStorage.setItem("texts", JSON.stringify(response.data));
                console.log("Texts fetched");
            });
    }
}
