export class Message {
    static messages = null;
    static setMessages = undefined;

    static severity = {
        INFO: "info",
        WARNING: "warning",
        ERROR: "error",
        SUCCESS: "success"
    }

    static show = (message, severity) => {
        console.info(`[Message] ${message}`);
        const index = `message-${Date.now()}`
        Message.setMessages([{
            index: index,
            message: message,
            severity: severity,
            isOpen: true,
        }, ...Message.messages]);
    }
}