import axios from "axios";

const handleModifyGroupOfAreas = (id, code, area_id, name, state, setPowerKW, stopLoadingFunction) => {

    const data = [
        {
          "area_id": area_id,
          "name": name,
          "mark": state
        }
    ];

    const url = `https://api.lightpass.gradis.pl/api/requests/${id}/${code}/areas`;

    axios.put(
        url, data, {
            headers: {
                'Accept-Language': localStorage.getItem("language") || "pl"
            }
        }
    ).then((response) => {
        console.log("Modify group of areas response: ", response);
        setPowerKW([response.data.power_kw_min, response.data.power_kw_avg, response.data.power_kw_max]);
        sessionStorage.setItem("powerKW", JSON.stringify([response.data.power_kw_min, response.data.power_kw_avg, response.data.power_kw_max]));
        stopLoadingFunction();
    });
}

export default handleModifyGroupOfAreas;