import * as turf from '@turf/turf';
import { AppMap } from "../utils/AppMap";

const handleAreasState = (areaArray) => {

    let positiveAreas;
    let negativeAreas;

    areaArray.forEach((area) => {
        if(area.state === 'negative') {
            if(negativeAreas === undefined) {
                negativeAreas = area;
            }
            negativeAreas = turf.union(negativeAreas.geometry, area.geometry);
        }
        if(area.state === 'positive') {
            if(positiveAreas === undefined) {
                positiveAreas = area;
            }
            positiveAreas = turf.union(positiveAreas.geometry, area.geometry);
        }
    });
    
    if(positiveAreas === undefined) {
        positiveAreas = turf.polygon([]);
    }

    if(negativeAreas === undefined) {
        negativeAreas = turf.polygon([]);
    }

    const features = areaArray.map((area) => {
        return {
            ...area, 
            state: 'neutral', 
        };
    });

    try {
        if(positiveAreas.geometry.coordinates.length > 0) {
            features.push({
                'geometry': (turf.difference(positiveAreas.geometry, negativeAreas.geometry)).geometry, 
                'state': 'positive'
            });
        }
    } catch (error) {
        console.error("Positive areas drawing error: ", error);
    }

    try {
        if(negativeAreas.geometry.coordinates.length > 0) {
            features.push({
                'geometry': negativeAreas.geometry, 
                'state': 'negative'
            });
        }
    } catch (error) {
        console.error("Negative areas drawing error: ", error);
    }

    try {
        AppMap.drawMultiple(features);
    } catch (error) {
        console.error("Areas state drawing error: ", error);
    }

    //handleModifyGroupOfAreas(id, code);
}

export default handleAreasState;

