// * React and Redux: 
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { setLoading } from '../features/LoadingSlice';
import { IsMobileContext } from "../App";
import { TabletContext } from '../App';

// * Material UI components:
import {
    Grid, 
    Typography, 
    IconButton, 
    Tooltip, 
    Switch,
    TextField, 
    Alert, 
    CircularProgress, 
    InputAdornment, 
    Stack, 
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';

// * Own components:
import Subarea from '../components/Subarea';
import StyledSkeleton from '../components/styled_components/StyledSkeleton';
import Paragraph from '../components/Paragraph';
import { CartButton } from '../components/buttons/CartButton';
import { Message } from "../utils/Message";

// * Map and handlers:
import { AppMap } from "../utils/AppMap";
import { addOwnArea } from '../handlers/handleOwnAreas';
import handleGetResults from "../handlers/handleGetResults";
import handleAreasState from '../handlers/handleAreasState.js';
import MessageContainer from "../components/MessageContainer";
import handleModifyGroupOfAreas from '../handlers/handleModifyGroupOfAreas';

const ConfigurationView = () => {

    const {id, code} = useParams();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading.isLoading);
    const texts = useSelector((state) => state.texts.texts);
    const [isHidden, setIsHidden] = useState(false);
    const [drawingMode, setDrawingMode] = useState(false);
    const [geometrySum, setGeometrySum] = useState(null);
    const [areaArray, setAreaArray] = useState([]);
    const { isMobile } = useContext(IsMobileContext);
    const { isTablet } = useContext(TabletContext);
    const [shouldAreaBeAdded, setAreaState] = useState(false);
    const [powerKW, setPowerKW] = useState([]);
    const [messages, setMessages] = useState([]);
    const [regionName, setRegionName] = useState("");

    useEffect(() => {
        handleGetResults(id, code, setGeometrySum, setAreaArray, setPowerKW);
        AppMap.init();
        sessionStorage.setItem('background', false);
    }, [id, code]);

    useEffect(() => {
        sessionStorage.setItem('drawingMode', drawingMode);
    }, [drawingMode])

    useEffect(() => {
        Message.messages = messages;
        Message.setMessages = setMessages;
    }, [messages]);

    useEffect(() => {
        sessionStorage.setItem("actualScroll", "0");
        const intervalId = setInterval(() => {
            const drawnGeometry = JSON.parse(sessionStorage.getItem('drawnGeometry')) || [];
        
            if(drawnGeometry !== null) {
                if (drawnGeometry.length > 2) {
                    setAreaState(true);
                }
            }
        }, 500);
        return () => clearInterval(intervalId);
    }, []);

    const closeMessage = (index) => {
        setMessages(messages.map((message, i) => {
            if (i === index) message.isOpen = false;
            return message;
        }));
    };

    const checkFunction = (value) => {
        areaArray[value - 1].state === "positive" ?
            areaArray[value- 1].state = "neutral" : 
        areaArray[value - 1].state === "neutral" ?
            areaArray[value - 1].state = "negative" :
        areaArray[value - 1].state = "positive";
        AppMap.clear();
    }

    const handleStopLoading = () => {
        dispatch(setLoading(false));
    }

    const DataDisplay = ({ data, text }) => {
        return (
        <Stack
            style={{padding: "0.5em", margin: "0.25em", border: "1px solid lightgrey", borderRadius: "0.5em"}}
        >   
            <Tooltip title={text} arrow={true}>
                <p style={{fontSize: "small", margin: "0"}}>{text}</p>
                <Typography 
                    variant='h5' 
                    className="centered"
                    style={{padding: '0', paddingTop: '0.25em', justifyContent: 'right'}}
                >
                    {data.toLocaleString()}
                </Typography>
                <p style={{textAlign: 'right', margin: '0', fontSize: 'small'}}>kW</p>
            </Tooltip>
        </Stack> );
    }

    return(
        <>
            <MessageContainer messages={messages} closeMessage={closeMessage}/>
            <div id="map"
                onClick={(_) => {
                    if(isMobile && !isHidden) setIsHidden(true);
                }}
            />
            <CartButton/>
            <Grid container className='MuiPaper-root configuration centered blured'>
                {
                    geometrySum === null ?
                    <CircularProgress color="success" /> :
                    <>
                        <Grid item xs={12} md={12}>
                            <Grid container>
                                <div
                                    onClick={(_) => {
                                        if(isMobile) setIsHidden(!isHidden);
                                    }}
                                    style={{display: "flex", width: "100%"}}
                                >
                                    <Grid container>
                                        <Grid item xs={isTablet ? 6 : 10} md={isTablet ? 6 : 10} className='configuration-item left'>
                                            <Typography variant='h5' style={{width: "100%"}}>
                                                { texts["configuration_panel_title"] !== undefined ? texts["configuration_panel_title"] : <StyledSkeleton/> }
                                            </Typography>
                                        </Grid>
                                        {
                                            isTablet ? <></> :
                                            <Grid item xs={2} md={2} className='configuration-item right'>
                                                <Tooltip
                                                    title={isHidden ? "Rozwiń panel" : "Zwiń panel"}
                                                    arrow={true}
                                                >
                                                    <IconButton className='centered' onClick={(_) => {
                                                        setIsHidden(!isHidden);
                                                    }}>
                                                        {
                                                            isHidden ?
                                                            <KeyboardArrowUpIcon /> :
                                                            <KeyboardArrowDownIcon />
                                                        }
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        }
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Grid container style={{marginTop: "0.5em"}}>
                                <Grid item xs={4} md={4} className={loading ? "centered" : ""}>
                                    {
                                        loading ? 
                                        <CircularProgress color="success"/> :
                                        <DataDisplay data={Math.round(powerKW[0])} text={texts["minumum"]}/>
                                    }   
                                </Grid>
                                <Grid item xs={4} md={4} className={loading ? "centered" : ""}>
                                    {
                                        loading ?
                                        <CircularProgress color="success"/> :
                                        <DataDisplay data={Math.round(powerKW[1])} text={texts["average"]}/>
                                    }
                                </Grid>
                                <Grid item xs={4} md={4} className={loading ? "centered" : ""}>
                                    {
                                        loading ?
                                        <CircularProgress color="success"/> :
                                        <DataDisplay data={Math.round(powerKW[2])} text={texts["maximum"]}/>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {
                                isHidden ? <></> :
                                    <Grid container style={{marginBottom: "0.1em"}}>
                                        <Grid item xs={isTablet ? 8 : 12} md={isTablet ? 8 : 12}>
                                            <>
                                                <Paragraph 
                                                    text={ texts["added_areas"] !== undefined ? texts["added_areas"] : <StyledSkeleton/> }
                                                    number={1}
                                                />
                                                {   
                                                    geometrySum === null ?
                                                    <Grid container className='centered'>
                                                        <CircularProgress color="success" />
                                                    </Grid> :
                                                    <Grid item xs={12} md={12}
                                                        className='configuration-list'
                                                        onScroll={(e) => {
                                                            sessionStorage.setItem("actualScroll", e.target.scrollTop);
                                                        }}
                                                        style={{
                                                            maxHeight: 
                                                                !isMobile && shouldAreaBeAdded ? "30vh" : 
                                                                !isMobile && !shouldAreaBeAdded ? "40vh" :
                                                                isMobile ? "25vh" : "30vh",
                                                            border: "1px solid lightgrey",
                                                        }}
                                                    >
                                                        {(() => {
                                                            const hasAdminLevelZero = areaArray.some(item => item.admin_level === 0);

                                                            return areaArray.map((item, index) => {

                                                                const percentage = hasAdminLevelZero ? null : (item.state === "positive" ? item.power_kw_avg / powerKW[1] : 0);

                                                                return (
                                                                    <Subarea
                                                                        key={ item.label }
                                                                        name={ item.label }
                                                                        adminLevel={ item.admin_level }
                                                                        state={ item.state }
                                                                        area_id={ item.area_id }
                                                                        defaultChecked={ true }
                                                                        isFirst={ index === 0 }
                                                                        onCheckboxClick={(_) => {
                                                                            checkFunction(item.id);
                                                                            handleModifyGroupOfAreas(id, code, item.area_id, item.label, item.state, setPowerKW, handleStopLoading);
                                                                            handleAreasState(areaArray);
                                                                        }}
                                                                        powerKW={ item.power_kw_avg }
                                                                        percentage={ percentage }
                                                                    />
                                                                );
                                                            });
                                                        })()}
                                                    </Grid>
                                                }
                                            </>
                                        </Grid>
                                        <Grid item xs={isTablet ? 4 : 12} md={isTablet ? 4 : 12}
                                              style={{paddingLeft: isTablet ? "2em" : "0em"}}
                                        >
                                            <>
                                                <Grid container style={{marginTop: '0.5em'}}>
                                                    <Grid item xs={6} md={6}>
                                                        <Paragraph 
                                                            text={ texts["area_correction"] !== undefined ? texts["area_correction"] : <StyledSkeleton/> } 
                                                            number={2}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} md={6} 
                                                        style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}
                                                    >
                                                        <Tooltip
                                                            title={drawingMode ? texts["correccion_button_checked"] : texts["correccion_button_unchecked"] }
                                                            arrow={true}
                                                        >
                                                            <Switch 
                                                                checked={drawingMode}
                                                                color='success'
                                                                onClick={(_) => {
                                                                    sessionStorage.setItem("drawingMode", !drawingMode);
                                                                    if(drawingMode) {
                                                                        sessionStorage.setItem('drawnGeometry', JSON.stringify([]));
                                                                        setAreaState(false);
                                                                    }
                                                                    setDrawingMode(!drawingMode);
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                                {
                                                    drawingMode ?
                                                    <Alert
                                                        variant='outlined'
                                                    >
                                                        { texts["drawing_tip"] }
                                                    </Alert> : <></>
                                                }
                                                {
                                                    shouldAreaBeAdded ?
                                                    <>
                                                        <p style={{marginTop: "1em"}}>Zdefiniowałeś ponad 3 punkty. Możesz dodać nowy obszar.</p>
                                                        <Grid item xs={12} md={12}>
                                                            <TextField
                                                                label="Podaj nazwę obszaru"
                                                                size='small'
                                                                color='success'
                                                                style={{width: "100%", paddingRight: "0"}}
                                                                value={regionName}
                                                                onChange={(e) => {
                                                                    setRegionName(e.target.value);
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        setAreaState(false);
                                                                        const drawnGeometry = JSON.parse(sessionStorage.getItem('drawnGeometry'));
                                                                        sessionStorage.setItem('drawnGeometry', JSON.stringify([]));
                                                                        if(geometrySum["geometry"] === undefined) {
                                                                            addOwnArea(drawnGeometry, regionName, geometrySum, areaArray, setAreaArray, id, code, setPowerKW);
                                                                        } else {
                                                                            addOwnArea(drawnGeometry, regionName, geometrySum["geometry"], areaArray, setAreaArray, id, code, setPowerKW);
                                                                        }
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton onClick={
                                                                                () => {
                                                                                    setAreaState(false);
                                                                                    const drawnGeometry = JSON.parse(sessionStorage.getItem('drawnGeometry'));
                                                                                    sessionStorage.setItem('drawnGeometry', JSON.stringify([]));
                                                                                    if(geometrySum["geometry"] === undefined) {
                                                                                        addOwnArea(drawnGeometry, regionName, geometrySum, areaArray, setAreaArray, id, code, setPowerKW);
                                                                                    } else {
                                                                                        addOwnArea(drawnGeometry, regionName, geometrySum["geometry"], areaArray, setAreaArray, id, code, setPowerKW);
                                                                                    }
                                                                                }
                                                                            }>
                                                                                <AddIcon/>
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </Grid>
                                                    </> : <></>
                                                }
                                            </>
                                        </Grid>
                                    </Grid>
                            }
                        </Grid>
                    </>
                }
            </Grid>
        </>
    );
}

export default ConfigurationView;
