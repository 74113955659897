import React, {useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
    Grid,
    Stack,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Button,
    TextField,
    CircularProgress,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {CartItemsContext} from "../App";
import {IsCartOpenedContext} from "../App";
import {CartItem} from "./CartItem";
import handleValidation from "../handlers/handleValidation";
import handleCalculation from "../handlers/handleCalculation";
import Recaptcha from "react-recaptcha";
import * as turf from '@turf/turf';
import {Config} from "../utils/Config";
import CheckIcon from "@mui/icons-material/Check";
import {AppMap} from "../utils/AppMap";
import {Message} from "../utils/Message";

const Cart = () => {

    const texts = useSelector((state) => state.texts.texts);
    const { cartItemsArray, setCartItemsArray } = useContext(CartItemsContext);
    const { setIsCartOpened } = useContext(IsCartOpenedContext);
    const [currentStep, setCurrentStep] = useState(0);
    const [email, setEmail] = useState("");
    const [processing, setProcessing] = useState(false);
    const steps = [
        texts["cart_title"] !== undefined ? texts["cart_title"] : "",
        texts["cart_verification"] !== undefined ? texts["cart_verification"] : "",
        texts["cart_success"] !== undefined ? texts["cart_success"] : "",
    ];

    const handleUnion = () => {

        let result = cartItemsArray[0].geometry;

        if (cartItemsArray.length === 1) {
            AppMap.draw(cartItemsArray[0].geometry, "transparent", true, false);
        } else {
            for (let i = 1; i < cartItemsArray.length; i++) {
                const currentFeature = cartItemsArray[i].geometry;
                if (currentFeature) {
                    try {
                        result = turf.union(result, currentFeature);
                    } catch (error) {
                        console.error('Union error:', error);
                    }
                }
            }
            localStorage.setItem('geometryData', JSON.stringify(result.geometry));
            AppMap.draw(result.geometry, "transparent", true, false);
        }
    }

    const handleRemoveItem = (itemToRemove) => {
        AppMap.polygonArray.forEach((polygon) => {
            if (polygon.id === itemToRemove.id) {
                AppMap.clear(polygon.id);
            }
        });
        const updatedItems = cartItemsArray.filter(item => item !== itemToRemove);
        setCartItemsArray(updatedItems);
    };

    return (
        <Grid container className="centered">
            <Stack style={{width: "100%"}}>
                <Typography className="centered" variant="h5">
                    {currentStep < steps.length ? steps[currentStep] : ""}
                </Typography>
                <Stepper alternativeLabel activeStep={currentStep} className="centered">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel
                                StepIconComponent={index === currentStep ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {
                    currentStep === 0 ?
                        <Stack spacing={{xs: 1, md: 1.5}}>
                            {cartItemsArray.map((item, index) => (
                                <CartItem
                                    key={index}
                                    name={item.name}
                                    admin_level={item.admin_level}
                                    geometry={item.geometry}
                                    width="calc(100% - 5px)"
                                    mode="cart"
                                    onRemove={() => handleRemoveItem(item)}
                                />
                            ))}
                            {cartItemsArray.length > 0 ?
                                <Grid container className="centered" style={{paddingLeft: "0", paddingRight: "0"}}>
            
                                    <Button className="button" variant="outlined" color="success" 
                                        style={{marginBottom: "1em"}}
                                        onClick={(_) => {
                                            handleUnion();
                                            setIsCartOpened(false);
                                        }}>
                                        { texts['cart_draw_button'] !== undefined ? texts['cart_draw_button'] : "Draw" }
                                    </Button>
                                    <Button className="button" variant="contained" color="success"
                                            onClick={(_) => {
                                                handleValidation(cartItemsArray) && setCurrentStep(currentStep + 1);
                                            }}
                                    >
                                        {
                                            texts['cart_confim_button'] !== undefined ? texts['cart_confim_button'] : "Next"
                                        }
                                    </Button>
                                </Grid> : <></>
                            }
                        </Stack> :
                        currentStep === 1 ?
                            processing ?
                                <div className="centered"><CircularProgress color="success"/></div> :
                                <Stack>
                                    <TextField
                                        color="success"
                                        label="Podaj adres e-mail"
                                        variant="filled"
                                        size="small"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        style={{
                                            width: "100%",
                                            borderRadius: "0.5em",
                                        }}
                                    />
                                    <Recaptcha
                                        className="centered"
                                        sitekey={Config.getValue("recaptcha")}
                                        render="explicit"/>
                                    <Button
                                        className="button"
                                        variant="contained"
                                        color="success"
                                        onClick={(_) => {
                                            if (handleValidation(cartItemsArray)) {
                                                setProcessing(true);
                                                handleCalculation(email, cartItemsArray)
                                                    .then(() => {
                                                        setCurrentStep(currentStep + 1);
                                                        setProcessing(false);
                                                    })
                                                    .catch((error) => {
                                                        setProcessing(false);
                                                        Message.show(
                                                            error.response.data.message || error.message,
                                                            Message.severity.ERROR
                                                        );
                                                        console.error(error);
                                                    });
                                            }
                                        }}
                                    >Wyślij do obliczeń</Button>
                                </Stack> :
                            currentStep === 2 ?
                                <Grid container>
                                    <Grid item xs={12} className="centered">
                                        <CheckIcon color="success"/>
                                    </Grid>
                                    <Grid item xs={12} md={12} className="centered"
                                        style={{justifyText: "center", paddingTop: "0"}}
                                    >
                                        <p>
                                            Sprawdź swoją skrzynkę pocztową
                                        </p>
                                    </Grid>
                                </Grid> :
                                <></>
                }
            </Stack>
        </Grid>
    );
};

export default Cart;