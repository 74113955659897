import {AppMap} from "../utils/AppMap";
import axios from "axios";
import {Message} from "../utils/Message";

const handleSearch = (searchQuery, setFetching, setSearchResults, setAutocomplete) => {

    setFetching(true);
    const url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_SEARCH;
    axios.get(url, {
        params: { // TODO: make parameters customizable
            q: searchQuery,
            country_code: "PL",
            viewport: AppMap.getViewport().join(","),
            order: "alpha",
        },
        headers: {
            'Accept-Language': localStorage.getItem("language") || "pl"
        }
    }).then((response) => {
        setFetching(false);
        setAutocomplete(false);
        setSearchResults(response.data.features);
    }).catch((error) => {
        setFetching(false);
        Message.show("Brak rezultatów", Message.severity.ERROR);
        console.error(error);
    });
};

export default handleSearch;
