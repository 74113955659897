import {union} from "@turf/turf";
import AreaValidator from "../validators/AreaValidator";
import RelationCountValidator from "../validators/RelationCountValidator";
import {Message} from "../utils/Message";

const handleValidation = (cart) => {
    let u = cart[0]["geometry"];
    for (let i = 1; i < cart.length; i++) {
        u = union(u, cart[i]["geometry"]).geometry;
    }

    try {
        AreaValidator(u);
        RelationCountValidator(u);
    } catch (e) {
        Message.show(e.message, Message.severity.ERROR);
        return false;
    }

    return true;
};

export default handleValidation;
