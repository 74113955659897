// * React and Redux:
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../features/LoadingSlice';

// * Material UI components:
import { 
    Checkbox, 
    Chip, 
    Divider,
    Avatar,
    Stack,
    Tooltip,
    Typography,
    ListItem,
    ListItemText,
    ListItemAvatar,
} from '@mui/material';

// * Own components:
import { StyledProgress } from "./styled_components/StyledProgress";

// * Other:
import { v4 as uuidv4 } from 'uuid';

const Subarea = ({ name, adminLevel, onClick, onDeleteClick, onCheckboxClick, isFirst, state, powerKW, percentage }) => {
    
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading.isLoading);
    const texts = useSelector((state) => state.texts.texts);
    const uniqueId = `subarea-${uuidv4()}`;
    const [checkboxState, setCheckboxState] = useState('positive');

    useEffect(() => {
        
        setCheckboxState(
            state === 'positive' ? 'positive' :
            state === 'negative' ? 'negative' :
            'neutral'
        );
    }, [state]);

    return (
        <div onClick={onClick} id={uniqueId}>
            {
                isFirst ? <></> : <Divider light={true}/>
            }
            <ListItem>
                {
                    percentage !== null ?
                    <ListItemAvatar>
                        <Avatar style={{background: "inherit", border: "1px solid grey", color: "grey"}}>
                            <StyledProgress value={(percentage * 100).toFixed(2)} mode="subarea"/>
                        </Avatar>
                    </ListItemAvatar> : <></>
                }
                <ListItemText style={{fontWeight: "bold"}}>
                    <Stack>
                        <Typography variant="h7" style={{marginBottom: "0.5em"}}>
                            {name}
                        </Typography>
                        <Tooltip
                            title={texts['tooltip_admin_level']}
                            arrow
                        >
                            <Chip
                                avatar={ 
                                    adminLevel !== 0 ?
                                    <Avatar style={{background: 'transparent'}}>{adminLevel}</Avatar> : <></>
                                }
                                clickable
                                style={{width: "fit-content", border: "1px solid grey"}}
                                label={ 
                                    <Typography variant="p" style={{marginLeft: "0.25em"}}>
                                        { texts[`admin_level_${adminLevel}`] !== undefined ? texts[`admin_level_${adminLevel}`] : "" } 
                                    </Typography>
                                }
                            />
                        </Tooltip>
                    </Stack>
                </ListItemText>
                <Tooltip
                    title={
                        checkboxState === 'positive' ? texts['checkbox_checked'] :
                        checkboxState === 'negative' ? texts['checkbox_negative'] :
                        texts['checkbox_unchecked']
                    }
                >
                    <Checkbox 
                        className='right' 
                        disabled={loading}
                        checked={checkboxState === 'positive'}
                        indeterminate={checkboxState === 'negative'}
                        color= {
                            checkboxState === 'positive' ? 'success' :
                            checkboxState === 'negative' ? 'error' :
                            'default'
                        }
                        onClick={(_) => {
                            console.log(percentage, powerKW);
                            setCheckboxState(
                            checkboxState === 'positive' ? 'neutral' :
                            checkboxState === 'negative' ? 'positive' :
                            'negative');
                            onCheckboxClick();
                            if(!loading) dispatch(setLoading(true));
                        }}  
                    />
                </Tooltip>
            </ListItem>
        </div>
    );
};

export default Subarea;