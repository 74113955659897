import React, {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {useParams} from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import ErrorIcon from "@mui/icons-material/Error";

const VerifyView = () => {
    const {id, code} = useParams();
    const [status, setStatus] = useState(0);
    const [message, setMessage] = useState("");

    useEffect(() => {

        const url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_REQUESTS_VERIFY;

        axios.post(url, {
            id: id,
            verif_code: code, 
            headers: {
                'Accept-Language': localStorage.getItem("language") || "pl"
            }
        }).then((_) => {
            setStatus(1);
            setMessage("Request verified successfully.");
        }).catch((error) => {
            setStatus(-1);
            setMessage(error.response.data.msg);
        });
    }, [id, code]);

    return (
        <div className="status-message">
            {
                status < 0 ?
                    <ErrorIcon color="error" style={{fontSize: "63px"}}/> :
                    status > 0 ?
                        <CheckIcon color="success" style={{fontSize: "63px"}}/> :
                        <CircularProgress/>
            }
            <h1>{message}</h1>
        </div>
    );
};

export default VerifyView;
