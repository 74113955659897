import React, {useState, createContext, useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setTexts } from './features/TextsSlice';
import MainView from './views/MainView';
import {useGeographic} from "ol/proj";
import {Config} from "./utils/Config";
import axios from "axios";
import TestView from "./views/TestView";
import VerifyView from "./views/VerifyView";
import StatusView from "./views/StatusView";
import ConfigurationView from './views/ConfigurationView';
import {Texts} from "./utils/Texts";

export const CartItemsContext = createContext();
export const IsCartOpenedContext = createContext();
export const VerificationContext = createContext();
export const IsMobileContext = createContext();
export const SelectedItemContext = createContext();
export const SelectorContext = createContext();
export const MapContext = createContext();
export const TabletContext = createContext();
export const CartItemsLayersContext = createContext();
 
export default function App() {
  const [cartItemsArray, setCartItemsArray] = useState([]);
  const [isCartOpened, setIsCartOpened] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 900
  );
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 900 && window.innerWidth > 600);
  const [selectedItem, setSelectedItem] = useState(null); 
  const [selectorActive, setSelectorActive] = useState(false);
  const [cartItemsLayers, setCartItemsLayers] = useState([]);

  const dispatch = useDispatch();
  const currentLanguage = useSelector(state => state.language.language);

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth < 900) setIsMobile(true);
      else setIsMobile(false);

      if(window.innerWidth <= 900 && window.innerWidth > 600) setIsTablet(true);
      else setIsTablet(false);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize)
    };
  }, []);
 
  useGeographic(); // Use degrees

  const getTexts = async () => {
    const url = 'https://api.lightpass.gradis.pl/api/config/texts';
    axios.get(url, {
      headers: {
        'Accept-Language': currentLanguage
      }
    }).then((response) => {
      dispatch(setTexts(response.data));
    }).catch((error) => {
      console.error("Get data error: ", error);
    });
  }

  Config.fetch().catch((error) => {
    console.error(error);
    setIsLocked(true);
  });

  Texts.fetch().then(() => {
    getTexts();
    setIsReady(true);
  }).catch((error) => {
    console.error(error);
    setIsLocked(true);
  });

  // Check for config updates on every request
  axios.interceptors.response.use(function (response) {
    if (Config.getValue("lastUpdate") < response.headers["config-last"]) {
      console.log(Config.getValue("lastUpdate"), response.headers["config-last"])
      Config.fetch(response.headers["config-last"]).catch((error) => {
        console.error(error);
        setIsLocked(true);
      });
    }
    return response;
  }, (error) => Promise.reject(error));

  return (
    <CartItemsContext.Provider value={{cartItemsArray, setCartItemsArray}}>
      <IsCartOpenedContext.Provider value={{isCartOpened, setIsCartOpened}}>
        <IsMobileContext.Provider value={{isMobile, setIsMobile}}>
          <VerificationContext.Provider value={{isVerified, setIsVerified}}>
            <SelectedItemContext.Provider value={{selectedItem, setSelectedItem}}>
              <SelectorContext.Provider value={{selectorActive, setSelectorActive}}>
                <TabletContext.Provider value={{isTablet, setIsTablet}}>
                  <CartItemsLayersContext.Provider value={{cartItemsLayers, setCartItemsLayers}}>
                    <Router>
                      <Routes>
                        <Route exact path='/' element={<MainView ready={isReady} locked={isLocked}/>}/>
                        <Route path='/requests/verify/:id/:code' element={<VerifyView/>}/>
                        <Route path='/requests/status/:id/:code' element={<StatusView/>}/>
                        <Route path='/requests/:id/:code' element={<ConfigurationView/>}/>
                        <Route path='/requests/:id/:code/test' element={<TestView/>}/>
                      </Routes>
                    </Router>
                  </CartItemsLayersContext.Provider>
                </TabletContext.Provider>
              </SelectorContext.Provider>
            </SelectedItemContext.Provider>
          </VerificationContext.Provider>
        </IsMobileContext.Provider>
      </IsCartOpenedContext.Provider>
    </CartItemsContext.Provider>
  );
}
