import axios from "axios";

const handleAddNewAreaToAge = async (coordinates, id, code, area_name) => {

    const arrayToString = (arr) => {
        if (Array.isArray(arr)) {
            return "[" + arr.map(arrayToString).join(", ") + "]";
        } else {
            return arr.toString();
        }
    };
      
    const resultString = arrayToString(coordinates);

    try {
        await axios.post(`https://api.lightpass.gradis.pl/api/requests/${id}/${code}/areas`, { 
            name: area_name,
            geojson: `{ "type": "Feature", "geometry": { "type": "Polygon", "coordinates": ${resultString} }, "properties": { "name": "${area_name}" } }`,
            headers: {
                'Accept-Language': localStorage.getItem("language") || "pl"
            }
        }).then((response) => {
            console.log("Add new area to age response: ", response);
            const responseFeature = response.data.areas.features[response.data.areas.features.length - 1];
            sessionStorage.setItem("responseFeature", JSON.stringify(responseFeature));
            return responseFeature;
        });
    } catch (error) {
        console.error("Error while adding new area to age: ", error);
        sessionStorage.setItem("responseFeature", JSON.stringify(null));
    }
}

export default handleAddNewAreaToAge;
