import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTexts } from '../../features/TextsSlice';
import { changeLanguage } from '../../features/LanguageSlice';
import { 
    IconButton,
    Dialog,
    DialogTitle, 
    DialogContent, 
    Tooltip,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import ReactCountryFlag from "react-country-flag";
import LanguageIcon from '@mui/icons-material/Language';
import axios from 'axios';

const LanguageButton = () => {

    const dispatch = useDispatch();
    const currentLanguage = useSelector(state => state.language.language);
    const texts = useSelector(state => state.texts.texts);
    const [open, setOpen] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('language') || "pl");

    const getTexts = async () => {
        const url = 'https://api.lightpass.gradis.pl/api/config/texts';
        axios.get(url, {
            headers: {
                'Accept-Language': currentLanguage
            }
        }).then((response) => {
            dispatch(setTexts(response.data));
        }).catch((error) => {
            console.error("Get data error: ", error);
        });
    }

    return (
        <>
            <Tooltip
                title={ texts["tooltip_language"] !== undefined ? texts["tooltip_language"] : "" }
                arrow={true}
            >
                <IconButton
                    className="centered"
                    style={{borderRadius: "0.5em", padding: "1em"}}
                    onClick={() => setOpen(!open)}
                    children={<LanguageIcon/>}
                />
            </Tooltip>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>
                    {
                        texts["language_choice"] !== undefined ? texts["language_choice"] : ""
                    }
                </DialogTitle>
                <DialogContent>
                    <FormGroup>
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    checked={language === "en"}
                                    color="success"
                                    defaultChecked={language === "en"}
                                    onClick={() => {
                                        setLanguage("en");
                                        if(currentLanguage === "pl") {
                                            dispatch(changeLanguage("en"));
                                            getTexts();
                                        }
                                    }}
                                />
                            }
                            label={
                                <span style={{display: "flex", alignItems: "center"}}>
                                    <ReactCountryFlag
                                        style={{width: "1.3em", border: "1px solid lightgrey", borderRadius: "0.25em"}}
                                        countryCode="GB"
                                        svg
                                    />
                                    <p style={{marginLeft: "0.5em"}}>English</p>
                                </span>
                            }
                        />
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    checked={language === "pl"}
                                    color="success"
                                    defaultChecked={language === "pl"}
                                    onClick={() => { 
                                        setLanguage("pl");
                                        if(currentLanguage === "en") {
                                            dispatch(changeLanguage("pl"));
                                            getTexts();
                                        }
                                    }}
                                />
                            } 
                            label={
                                <span style={{display: "flex", alignItems: "center"}}>
                                    <ReactCountryFlag
                                        style={{width: "1.3em", border: "1px solid lightgrey", borderRadius: "0.25em"}}
                                        countryCode="PL"
                                        svg
                                    />
                                    <p style={{marginLeft: "0.5em"}}>Polski</p>
                                </span>
                            }
                        />
                    </FormGroup>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default LanguageButton;