// * React and Redux:
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// * Material UI components:
import {
    Grid, 
    Typography, 
    TextField,
    InputAdornment, 
    Tooltip,
    IconButton, 
    Stack, 
    CircularProgress, 
    Divider, 
    LinearProgress,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

// * Own components:
import { CartItem } from "./CartItem";

// * Map and handlers:
import handleSearch from "../handlers/handleSearch";
import handleAutocomplete from '../handlers/handleAutocomplete';

const Header = ({ ready, isMobile }) => {

    const texts = useSelector(state => state.texts.texts);
    const [fetching, setFetching] = useState(false);
    const [autocomplete, setAutocomplete] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [autocompleteResults, setAutocompleteResults] = useState([]);
    const [mode, setMode] = useState('home');

    const search = () => handleSearch(searchQuery, setFetching, setSearchResults, setAutocomplete);

    return (
        <Grid container className='header centered blured'> 
            {
                !isMobile ? 
                <Grid item xs={12} md={12}>
                    <Typography className='centered' variant='h4'>LIGHTPASS</Typography>
                </Grid> :
                <></>
            }
            <Grid item xs={12} md={12}>
                <TextField
                    color="success"
                    label={ texts["search_placeholder"] !== undefined ? texts["search_placeholder"] : "" }
                    variant="filled"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                        if(e.target.value.length > 2) {
                            setMode('search');
                            handleAutocomplete(e.target.value, setAutocomplete, setAutocompleteResults);
                        }
                        if(e.target.value.length <= 2) {
                            setAutocompleteResults([]);
                            setSearchResults([]);
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            search();
                            setMode('search');
                        }
                    }}
                    style={{
                        width: '100%',
                        borderRadius: '0.5em'
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip 
                                    title={texts["tooltip_search"] !== undefined ? texts["tooltip_search"] : ""}
                                    placement="top"
                                    arrow
                                >
                                    <IconButton onClick={
                                        () => {
                                            search();
                                            setMode('search');
                                        }
                                    }>
                                        <SearchIcon/>
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            {
                
            }
            <Grid item xs={12} md={12} style={{paddingTop: mode === "home" ? "1em" : "0em"}}>
                {
                    mode === "home" ?
                    <Typography variant="p" className="about">
                        {
                            texts["under_search"] !== undefined ? texts["under_search"] : <LinearProgress color="success"/>
                        }
                    </Typography> :
                    mode === "search" ?
                    <>
                        {
                            autocomplete ? (
                                <Grid className='autocomplete-container centered' 
                                    style={{padding: "0", 
                                        boxShadow: autocompleteResults.length > 0 ? "rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px" : "none"
                                        ,marginTop: autocompleteResults.length > 0 ? "1em" : "0em"
                                    }}
                                >
                                    <Stack style={{width: "100%"}}>
                                        {autocompleteResults.map((result, index) => (
                                            <>
                                                {index === 0 ? null : <Divider />}
                                                <div key={result}
                                                    style={{display: "flex", width: "100%", }}
                                                    onClick={(_) => {
                                                        setSearchQuery(result);
                                                        search();
                                                        setAutocomplete(false);
                                                    }}
                                                >
                                                    <p style={{color: 'black', marginLeft: "1em"}}>
                                                        {result}
                                                    </p>
                                                </div>
                                            </>
                                        ))}
                                    </Stack>
                                </Grid>
                            ) : null
                        }
                        {
                            fetching ? 
                            <Grid className='centered' style={{paddingTop: "2em"}}>
                                <CircularProgress color="success"/>
                            </Grid> : 
                            <Stack className="results" spacing={{md: 1.5}} 
                                style={{paddingTop: searchResults.length > 0 ? "1em" : "0em"}}
                            >
                                {searchResults.map((result) => (
                                    <CartItem
                                        key={result.id}
                                        osm_id={result.properties.osm_id}
                                        name={result.properties.name}
                                        admin_level={result.properties.admin_level}
                                        geometry={result.geometry}
                                        width={
                                            isMobile && searchResults.length === 1 ? 'calc(100% - 5px)' : 
                                            isMobile && searchResults.length !== 1 ? 'calc(80% - 5px)' :
                                            'calc(100% - 5px)'
                                        }
                                        mode="header"
                                    />
                                ))}
                            </Stack>
                        }
                    </> :
                    <></>
                }
            </Grid>
        </Grid>
    );
}

export default Header;