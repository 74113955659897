import React from 'react';
import { Grid } from '@mui/material';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';

const Paragraph = ({text, number}) => {
    return(
        <Grid item xs={12} md={12} style={{
            marginBottom: "1em", marginTop: "1em",
            display: 'flex', alignItems: 'center'
        }}>
            {
                number === 1 ? <LooksOneIcon color='success' style={{marginRight: '0.25em'}}/> :
                number === 2 ? <LooksTwoIcon color='success' style={{marginRight: '0.25em'}}/> : 
                number === 3 ? <Looks3Icon color='success' style={{marginRight: '0.25em'}} /> : <></>
            }
            {text}
        </Grid>
    );
}

export default Paragraph;