import axios from "axios";

const handleAutocomplete = (autocompleteQuery, setAutocomplete, setAutocompleteResults) => {

    const url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_SEARCH_COMPLETION;
    axios.get(url, {
        params: {
            q: autocompleteQuery
        },
        headers: {
            'Accept-Language': localStorage.getItem("language") || "pl"
        }
    }).then((response) => {
        setAutocomplete(true);
        setAutocompleteResults(response.data.results);
    }).catch((error) => {
        console.error(`Autocomplete error occured: ${error}`);
    })
}

export default handleAutocomplete;





