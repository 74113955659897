import React from 'react';
import { Skeleton } from '@mui/material';

const StyledSkeleton = () => {
    return <Skeleton 
        width="100%"
        animation="wave"
    />
}

export default StyledSkeleton;