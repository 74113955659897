import {Config} from "../utils/Config";
import {area} from "@turf/turf";

const AreaValidator = (polygon) => {
    let maxArea = Config.getValue("requests_max_area_km2");
    if (area(polygon) / 1000000 > maxArea) {
        throw new Error(`Powierzchnia jest większa niż ${maxArea} km²`);
    }

    return true;
};

export default AreaValidator;
