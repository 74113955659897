import axios from "axios";

export class Config {
    static get() {
        return JSON.parse(sessionStorage.getItem("config")) ?? {};
    }

    static getValue(key) {
        let config = Config.get();
        if (!Object.keys(config).includes(key)) return null;
        return config[key]
    }

    static set(config) {
        sessionStorage.setItem("config", JSON.stringify(config));
    }

    static update(key, value) {
        let config = Config.get();
        config[key] = value;
        Config.set(config);
    }

    static async fetch(lastUpdate = -1, url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_CONFIG) {

        if (lastUpdate >= 0) Config.update("lastUpdate", lastUpdate);
        return axios.get(url, {
            headers: {
                'Accept-Language': localStorage.getItem("language") || "pl"
            }
        })
            .then((response) => {
                Config.set(response.data);
                Config.update("lastUpdate", response.headers["config-last"]);
                console.log("Config fetched");
            });
    }
}
