import React from "react";
import {Alert, Snackbar} from "@mui/material";

const MessageContainer = ({messages, closeMessage}) => {
    return (
        <div id="alerts">
            {
                messages.map((message, index) => (
                    <Snackbar open={message.isOpen}
                              autoHideDuration={5000}
                              onClose={() => closeMessage(index)}
                              anchorOrigin={{vertical: "top", horizontal: "center"}}>
                        <Alert key={index} severity={message.severity} onClose={() => closeMessage(index)}>
                            {message.message}
                        </Alert>
                    </Snackbar>
                ))
            }
        </div>
    );
};

export default MessageContainer;