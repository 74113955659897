import axios from "axios";
import * as turf from '@turf/turf'; 
import { AppMap } from "../utils/AppMap";
import { Message } from "../utils/Message";
import handleAreasState from "./handleAreasState";

const handleGetResults = (id, code, setGeometrySum, setAreaArray, setPowerKW) => {

    const start = new Date().getTime();

    const url = `https://api.lightpass.gradis.pl/api/requests/${id}/${code}`
    axios.get(
        url, {
            headers: {
                'Accept-Language': localStorage.getItem("language") || "pl"
            }
        }
    ).then((response) => {
        
        const end = new Date().getTime();
        console.log("Request time: ", (end - start) / 1000, "s");

        setPowerKW([response.data.power_kw_min, response.data.power_kw_avg, response.data.power_kw_max]);
        
        const data = (response.data)['areas'];
        let geometriesSum = data['features'][0]['geometry'];
        
        for(let i = 1; i < data['features'].length; i++) {
            const currentGeometry = data['features'][i]['geometry'];
            if(currentGeometry) {
                try {
                    geometriesSum = turf.union(geometriesSum, currentGeometry);
                } catch (error) {
                    console.error('Union error: ', error);
                }
            }
        }

        localStorage.setItem("features", JSON.stringify(data['features']));
        if(geometriesSum["geometry"] === undefined) {
            localStorage.setItem("geometrySum", JSON.stringify(geometriesSum));
        } else {
            localStorage.setItem("geometrySum", JSON.stringify(geometriesSum["geometry"]));
        }
        const dividedArea = divideArea({ areaToDivide: data['features'] });
        setAreaArray(dividedArea);
        localStorage.setItem("areaArray", JSON.stringify(dividedArea));
        setGeometrySum(geometriesSum);

        try {
            handleAreasState(dividedArea);
        }
        catch(error) {
            Message.show(error, Message.severity.ERROR);
            console.error("Drawing error: ", error);
        }

        console.log("Geometries sum: ", geometriesSum);

        if(geometriesSum.geometry !== undefined) {
            AppMap.draw(getAreaDifference(geometriesSum.geometry).geometry, 'background', false, true);
        } else {
            AppMap.draw(getAreaDifference(geometriesSum).geometry, 'background', false, true);
        }
  
    }).catch((error) => {
        Message.show(error.message, Message.severity.ERROR);
        console.error("Fetching error: ", error);
    });
}

const divideArea = ({ areaToDivide }) => {

    const areaArray = [];

    areaToDivide.forEach(createRow);

    function createRow(value, index) {
        const row = {
            id: (index + 1), 
            label: value['properties'].name, 
            admin_level: value['properties'].admin_level,
            geometry: value['geometry'], 
            state: value['properties'].mark,
            power_kw_min: value['properties'].power_kw_min,
            power_kw_avg: value['properties'].power_kw_avg,
            power_kw_max: value['properties'].power_kw_max,
            area_id: value['properties'].id
        };
        areaArray.push(row);
    }
    return(areaArray);
}

const getAreaDifference = (geometry) => {
    const extent = AppMap.instance.getView().calculateExtent();
    const mapGeometry = {
        type: 'Polygon',
        coordinates: [[
            [extent[0], extent[1]],
            [extent[2], extent[1]],
            [extent[2], extent[3]],
            [extent[0], extent[3]],
            [extent[0], extent[1]],
        ]],
    };

    return turf.difference(mapGeometry, geometry);
}

export default handleGetResults;