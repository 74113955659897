import {Box, Typography, CircularProgress} from "@mui/material";

export function StyledProgress(props) {
    return (
        <Box sx={{position: "relative", display: "inline-flex"}}>
            <CircularProgress 
                variant="determinate" {...props} 
                color="success"
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    className={props.value < 100 && props.mode === "status" ? "blink" : ""}
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}