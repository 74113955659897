// * React and Redux: 
import React, {useEffect, useState, useContext} from "react";
import { IsCartOpenedContext } from "../App";

// * Material UI components:
import {Grid, Drawer, IconButton} from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';

// * Own components:
import Cart from "../components/Cart";
import Header from "../components/Header";
import { Message } from "../utils/Message";
import { CartButton } from "../components/buttons/CartButton";
import MessageContainer from "../components/MessageContainer";

// * Map and handlers:
import {AppMap} from "../utils/AppMap";

const MainView = ({ready, locked}) => {

    const { isCartOpened, setIsCartOpened } = useContext(IsCartOpenedContext);
    const [isMobile, setIsMobile] = useState();
    const [messages, setMessages] = useState([]);
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const closeMessage = (index) => {
        setMessages(messages.map((message, i) => {
            if (i === index) message.isOpen = false;
            return message;
        }));
    };

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
            setIsMobile(dimensions.width < 870);
        };

        AppMap.init();
        AppMap.getZoomLevel();
        AppMap.getCorners();
        setIsMobile(window.innerWidth < 870);
        window.addEventListener('resize', handleResize, false);

        return () => {
            window.removeEventListener('resize', handleResize, false);
        };
    }, [dimensions.width]);

    useEffect(() => {
        Message.messages = messages;
        Message.setMessages = setMessages;
    }, [messages]);

    return (
        <>
            <MessageContainer messages={messages} closeMessage={closeMessage}/>
            {
                locked ?
                    <div className="locked-backdrop">
                        <div className="locked-modal">
                            <ErrorIcon color="error" style={{fontSize: "63px"}}/>
                            <h1>Nie możemy połączyć się z API</h1>
                            <p>Spróbuj ponownie później</p>
                        </div>
                    </div> : <></>
            }
            <div id="map"></div>
            <>
                <Grid container>
                    <Header ready={ready} isMobile={isMobile} />
                    <CartButton mode="main" clickEvent={(_) => setIsCartOpened(true)}/>
                    <Drawer anchor={isMobile ? "bottom" : "right"}
                        open={isCartOpened}
                        onClose={(_) => setIsCartOpened(false)}
                        transitionDuration={500}
                        PaperProps={{
                            style: {
                                width: isMobile ? '100%' : '40%',
                                maxWidth: 'none',
                            },
                        }}
                    >
                        {
                            isMobile ?
                            <Grid container style={{position: 'absolute', justifyContent: 'right'}}>
                                <IconButton onClick={(_) => setIsCartOpened(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid> : <></>
                        }
                        <Cart />
                    </Drawer>
                </Grid>
            </>
        </>
    );
}

export default MainView;

