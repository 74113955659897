// * React:
import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import {useParams} from "react-router-dom";

// * Material UI components:
import { Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";

// * Own components:
import { StyledProgress } from "../components/styled_components/StyledProgress";

// * Other:
import axios from "axios";

const StatusView = () => {

    const texts = useSelector((state) => state.texts.texts);
    const {id, code} = useParams();
    const [status, setStatus] = useState(0);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const target = `/requests/${id}/${code}`;

    useEffect(() => {
        
        const url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_REQUESTS_STATUS;

        let update = () => {
            console.log("Check for status...");
            axios.get(url, {
                params: {
                    id: id,
                    verif_code: code,
                },
                headers: {
                    'Accept-Language': localStorage.getItem("language") || "pl"
                }
            }).then((response) => {
                setStatus(response.data.code);
                setMessage(response.data.msg);
                setProgress(response.data.percentage);
            }).catch((error) => {
                setStatus(-1);
                setMessage("Unable to fetch status for this request");
                console.error(error);
            });
            setTimeout(update, 30 * 1000);
        };
        update();
    }, [id, code]);

    return (
        <div className="status-message">
            {
                status < 0 ?
                    <ErrorIcon color="error" style={{fontSize: "63px"}}/> :
                    status === 100 ?
                        <CheckIcon color="success" style={{fontSize: "63px"}}/> :
                        <StyledProgress color="success" value={progress} mode="status"/>
            }
            <h1>{message}</h1>
            {
                status === 100 &&
                <Button 
                    href={target}
                    variant="contained"
                    color="success"
                >
                    { texts["show_details"] } &raquo;
                </Button>
            }
        </div>
    );
};

export default StatusView;
