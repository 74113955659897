import axios from "axios";

const handleCalculation = async (email, cart) => {

    if (email && cart) {
        const url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_REQUESTS;
        console.log(cart.map(el => el.osm_id));
        return await axios.post(url, {
            email: email,
            relations: cart.map(el => el.osm_id),
            headers: {
                'Accept-Language': localStorage.getItem("language") || "pl"
            }
        }
    );
    }

    throw new Error("Cart is empty!");
};

export default handleCalculation;
