import { createSlice } from '@reduxjs/toolkit';

const powerKWSlice = createSlice({
    name: 'powerKW',
    initialState: {
        powerKWMin: 0,
        powerKWAvg: 0,
        powerKWMax: 0,
    },
    reducers: {
        setPowerKWMin(state, action) {
            state.powerKWMin = action.payload;
        },
        setPowerKWAvg(state, action) {
            state.powerKWAvg = action.payload;
        },
        setPowerKWMax(state, action) {
            state.powerKWMax = action.payload;
        },
    },
});

export const { setPowerKWMin, setPowerKWAvg, setPowerKWMax } = powerKWSlice.actions;
export default powerKWSlice.reducer;