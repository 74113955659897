import React, {useContext} from "react";
import { useSelector } from 'react-redux';
import {IconButton} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Grid, Badge, Tooltip } from "@mui/material";
import { CartItemsContext } from "../../App";
import LanguageButton from "./LanguageButton";

export const CartButton = ({mode, clickEvent, mouseEnterEvent, mouseLeaveEvent}) => {

    const texts = useSelector(state => state.texts.texts);
    const { cartItemsArray } = useContext(CartItemsContext);

    return (
        <Grid container className="button-container blured">
            <LanguageButton/>
            {
                mode === 'main' ?
                <Badge badgeContent={cartItemsArray.length} color="success">
                    <Tooltip
                        title={ texts["tooltip_cart_button"] !== undefined ? texts["tooltip_cart_button"] : "" }
                        arrow={true}
                    >
                        <IconButton
                            className="centered"
                            onClick={clickEvent}
                            onMouseEnter={mouseEnterEvent}
                            onMouseLeave={mouseLeaveEvent}
                            style={{borderRadius: "0.5em", padding: "1em"}}
                            children={<ShoppingCartIcon/>}
                        />
                    </Tooltip>
                </Badge> : <></>
            }
        </Grid>
    );
};