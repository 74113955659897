// * React and Redux: 
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

// * MUI components:
import { 
    Grid
} from '@mui/material'; 

// * Map and handlers:
import { AppMap } from "../utils/AppMap";
import handleGetResults from "../handlers/handleGetResults";
import handleRoads from "../handlers/handleRoads";

// * Own components:   
import RequestButton from '../components/buttons/RequestButton';

const TestView = () => {

    const {id, code} = useParams();
    const [geometrySum, setGeometrySum] = useState(null);
    const [areaArray, setAreaArray] = useState([]);
    const [powerKW, setPowerKW] = useState([]);

    useEffect(() => {
        while(0) console.log(geometrySum, areaArray, powerKW);
        handleGetResults(id, code, setGeometrySum, setAreaArray, setPowerKW);
        AppMap.init();
        AppMap.getCorners();
        sessionStorage.setItem('background', false);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRequest = () => {
        AppMap.getCorners();
        const leftTopCorner = JSON.parse(sessionStorage.getItem('currentCorners'))['left top'];
        const rightBottomCorner = JSON.parse(sessionStorage.getItem('currentCorners'))['right bottom'];

        const lonMin = leftTopCorner[0];
        const latMax = leftTopCorner[1];
        const lonMax = rightBottomCorner[0];
        const latMin = rightBottomCorner[1];
         
        console.log(leftTopCorner, rightBottomCorner);
        handleRoads(id, code, lonMin, latMax, lonMax, latMin);
    }

    const erase = () => {
        AppMap.clearRoads();
    }

    return (
        <>
            <Grid container className="button-container blured"
                style={{zIndex: 1000}}
            >
                <RequestButton 
                    handleRequest = {handleRequest}
                    erase = {erase}
                />
            </Grid>
            <div id="map"/>
        </>
    )
}

export default TestView;