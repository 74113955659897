import React, {useEffect, useState, useContext} from "react";
import { useSelector } from 'react-redux';
import {Chip, Grid, IconButton, Paper, Tooltip} from "@mui/material";
import {AppMap} from "../utils/AppMap";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RemoveIcon from "@mui/icons-material/Remove";
import CheckIcon from "@mui/icons-material/Check";
import {CartItemsContext} from "../App";
import { v4 as uuidv4 } from 'uuid';

export const CartItem = ({osm_id, name, admin_level, geometry, width, mode, onRemove}) => {
    
    const texts = useSelector(state => state.texts.texts);
    const {cartItemsArray, setCartItemsArray} = useContext(CartItemsContext);
    const [isItemAdded, setIsItemAdded] = useState(false);
    const uniqueId = `${uuidv4()}`;

    useEffect(() => {

        const isItemInArray = cartItemsArray.some(item =>
            item.name === name &&
            item.admin_level === admin_level &&
            JSON.stringify(item.geometry) === JSON.stringify(geometry),
        );
        setIsItemAdded(isItemInArray);
    }, [cartItemsArray, name, admin_level, geometry]);

    const handleAddItem = () => {
        const newItem = {
            "osm_id": osm_id,
            "name": name,
            "admin_level": admin_level,
            "geometry": geometry,
            "id": uniqueId,
        };

        if (!isItemAdded) {
            setCartItemsArray([...cartItemsArray, newItem]);
        }
    };

    const generateAdminLevelUnicode = (level) => {
        if (level >= 1 && level <= 20) {
            const unicodeCodePoint = 0x2460 + parseInt(level - 1);
            return String.fromCharCode(unicodeCodePoint);
        }
        return "";
    };

    return (
        <Paper
            className="cart-item"
            variant="none"
            onMouseEnter={(_) => {
                AppMap.draw(geometry, "green", true, false, uniqueId);
            }}
            onMouseLeave={(_) => {
                AppMap.clear(uniqueId);
            }}
            style={{ width: width }}
        >
            <Grid container>
                <Grid item xs={9} md={9} style={{ padding: "0.5em" }}>
                    <Grid container>
                        <Grid item xs={12} md={12} className="name">
                            <strong>{name}</strong>
                        </Grid>
                        <Grid item xs={12} md={12} className="admin-level">
                            <Tooltip
                                title={texts["tooltip_admin_level"] !== undefined ? texts["tooltip_admin_level"] : "" }
                                arrow={true}
                            >
                                <Chip
                                    label={
                                        <div className="admin-level-label">
                                            <p className="cart-item-unicode">
                                                {generateAdminLevelUnicode(admin_level)}
                                            </p>
                                            <p className="cart-item-paragraph">
                                            {
                                                texts[`admin_level_${admin_level}`] !== undefined ? texts[`admin_level_${admin_level}`] : ""
                                            }
                                            </p>
                                        </div>
                                    }
                                    className="centered"
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} md={3} className="centered">
                    {
                        mode === "header" && !isItemAdded ?
                        <Tooltip
                            title={ texts["tooltip_add_to_cart"] !== undefined ? texts["tooltip_add_to_cart"] : "" }
                            arrow={true}
                        >
                            <IconButton
                                onClick={(_) => {
                                    handleAddItem();
                                    setIsItemAdded(true);
                                }}
                            >
                                <AddShoppingCartIcon />
                            </IconButton>
                        </Tooltip> :

                        mode === "header" && isItemAdded ?
                        <CheckIcon color="success" /> :

                        mode === "cart" ?
                        <Tooltip
                            title={ texts["tooltip_remove_from_cart"] !== undefined ? texts["tooltip_remove_from_cart"] : "" }
                            arrow={true}
                        >
                            <IconButton
                                onClick={onRemove}
                            >
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip> :<></>
                    }
                </Grid>
            </Grid>
        </Paper>
    );
};